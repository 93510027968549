import React from "react"
import Layout from "../components/layout"
import HubspotForm from "react-hubspot-form"
import { Helmet } from "react-helmet"

function EbookRnR2() {
  return (
    <Layout>
      <Helmet>
          <title>Ultimate Guide to Rewards & Recognition | Free eBook | Vantage Circle
          </title>

          <meta
            name="description"
            content="Download our free eBook to discover effective strategies for employee rewards and recognition, enhancing engagement and performance in your organization."
          />
          <meta property="twitter:title" content="Ultimate Guide to Rewards & Recognition | Free eBook | Vantage Circle" />
          <meta name="robots" content="index" />

          <meta
            property="twitter:description"
            content="Download our free eBook to discover effective strategies for employee rewards and recognition, enhancing engagement and performance in your organization."
          />

          <meta property="og:type" content="website" />

          <meta
            property="og:url"
            content="https://www.vantagecircle.com/free-ebook-rewards-recognition-2/"
          />

          <meta property="og:site_name" content="Vantage Circle" />
          <meta property="og:title" content="Ultimate Guide to Rewards & Recognition | Free eBook | Vantage Circle" />

          <meta
            property="og:description"
            content="Download our free eBook to discover effective strategies for employee rewards and recognition, enhancing engagement and performance in your organization."
          />
          <link
            rel="canonical"
            href="https://www.vantagecircle.com/free-ebook-rewards-recognition-2/"
          ></link>
        </Helmet>
      <section className="bg-rnrbook mx-2  my-2 lg:mx-6 lg:my-6 rounded md:rounded-md w-auto">
        <div className="flex flex-col md:flex-row lg:justify-between container py-10 md:py-16 mx-auto px-6 relative">
          <div className="flex-1">
            <div className="flex flex-col">
              <div className="space-y-2">
                <div style={{ width: "fit-content", borderRadius: "3px" }}>
                  <img
                    className="p-0 m-0 transform w-8/12 -translate-x-1"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/12/Ribbon.png"
                  />
                </div>
              </div>
              <div className="mt-6">
                <h1 className="text-white text-2xl md:text-4xl xl:text-6xl font-medium text-left">
                  The Ultimate <br />
                  Guide to{" "}
                  <span className="text-newOrange">
                    Rewards <br />
                    and Recognition
                  </span>
                </h1>
              </div>
              <div>
                <ul
                  className="form-bullets list-inside green-rounded-bull w-full text-white"
                  style={{ marginTop: "1.5rem !important" }}
                >
                  <li className="text-white">
                    The Positive Effects of Rewards and Recognition on
                    Businesses{" "}
                  </li>
                  <li className="text-white">
                    Key Statistics on Rewards and Recognition{" "}
                  </li>
                  <li className="text-white">
                    The Role of Technology in Rewards and Recognition{" "}
                  </li>
                  <li className="text-white">
                    Building a Culture of Peer-to-Peer Recognition{" "}
                  </li>
                  <li className="text-white">
                    How to Build a Successful Employee Rewards and Recognition
                    Program
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="flex-1 hidden lg:block">
            <div className="flex justify-center items-center w-full h-full">
              <img
                className="flex-1 text-white m-0 w-3/4 h-auto"
                src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/book.png"
              ></img>
            </div>
          </div>
          <div className="flex-1 mt-10 lg:mt-0">
            <div className="flex justify-center md:justify-end items-start md:items-center mt-10 md:mt-0 w-auto h-full  ebookrnr2">
              <div className="w-11/12 form-section px-6 py-10 border border-white rounded-md shadow-lg bg-purple-30">
                <h2 className="mt-2 mb-8 text-xl text-gray-900">
                  Fill up to download your copy now
                </h2>
                <HubspotForm
                  portalId="6153052"
                  formId="6fa4436e-cb81-423b-813a-2ee06f2703cb"
                  onSubmit={() => console.log("Submit!")}
                  onReady={form => console.log("Form ready!")}
                  loading={<div>Loading...</div>}
                />
                {/* <div className="notetext text-center font-normal text-sm">We safeguard your personal information in accordance with our <a href="/privacy-policy/" target="_blank">Privacy Policy</a></div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default EbookRnR2
